import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import HeaderStack from '../stacks/HeaderStack';
import HeaderSearcherInput from '../inputs/HeaderSearcherInput';

const SearchHeader = ({
  title,
  subtitle,
  showGoBack,
  setHeaderFilters,
}) => {
  const [globalFilter, setGlobalFilter] = useState('');

  const onChangeHandler = (filter) => {
    setGlobalFilter(filter);
    setHeaderFilters((prevState) => ({ ...prevState, globalFilter: filter }));
  };

  return (
    <HeaderStack title={title} subtitle={subtitle} showGoBack={showGoBack}>
      <Box sx={{ minWidth: 400, marginLeft: 'auto' }}>
        <HeaderSearcherInput finder={{ onFinderChange: onChangeHandler, searchPlaceHolder: 'Buscar', finderValue: globalFilter }} />
      </Box>
    </HeaderStack>
  );
};

SearchHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  showGoBack: PropTypes.bool,
  setHeaderFilters: PropTypes.func,
};

SearchHeader.defaultProps = {
  showGoBack: false,
  subtitle: '',
  setHeaderFilters: () => {},
};

export default SearchHeader;
